@import "https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200";

/* If you need to add @import statements, do so up here */
@import "fonts.css";
@import "vendor";

@import "jit-refresh.css"; /* triggers frontend rebuilds */

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-fg: rgba(16, 16, 16, 1);
    --color-fg-secondary: rgba(16, 16, 16, 0.6);
    --color-fg-tertiary: rgba(16, 16, 16, 0.3);
    --color-fg-inverted: rgba(255, 255, 255, 1);
  }

  ::selection {
    background: theme("colors.brand");
    color: theme("colors.white");
  }

  body {
    color: theme("colors.black");
  }

  a {
    color: theme("colors.brand");
    text-decoration: underline;
  }

  b, strong {
    font-weight: theme("fontWeight.medium");
  }

  ol {
    list-style-type: disc;
  }
}

@layer components {
  s-section, .s-section {
    display: block;
    margin: theme("spacing.32") 0;
  }
  @media screen(sm) {
    s-section, .s-section {
      margin: theme("spacing.60") 0;
    }
  }

  s-container, .s-container {
    display: block;
    padding: 0 theme("spacing.4");
    margin: 0 auto;
    max-width: theme("screens.2xl");
  }
  @media screen(sm) {
    s-container, .s-container {
      padding: 0 theme("spacing.10");
    }
  }

  s-card, .s-card {
    display: block;
    background: theme("colors.z1");
    border-radius: theme("borderRadius.xl");
    overflow: hidden;
  }

  s-divider, .s-divider {
    display: block;
    height: theme("spacing.5");
    background: theme("colors.black");
  }

  /* Headlines */

  .s-headline-1 {
    font-size: theme("fontSize.4xl");
    line-height: theme("lineHeight.10");
    font-weight: theme("fontWeight.medium");
    max-width: 25ch;
  }
  @media screen(sm) {
    .s-headline-1 {
      font-size: theme("fontSize.5xl");
      line-height: theme("lineHeight.none");
    }
  }
  @media screen(lg) {
    .s-headline-1 {
      font-size: theme("fontSize.6xl");
      line-height: theme("lineHeight.none");
      letter-spacing: theme("letterSpacing.tight");
    }
  }

  /* .s-headline-2 {
    font-size: theme("fontSize.4xl");
    line-height: theme("lineHeight.tight");
    font-weight: theme("fontWeight.medium");
  } */

  .s-headline-2 {
    font-size: theme("fontSize.3xl");
    line-height: theme("lineHeight.9");
    font-weight: theme("fontWeight.medium");
  }
  /* @media screen(sm) {
    .s-headline-2 {
      font-size: theme("fontSize.2xl");
      line-height: theme("lineHeight.8");
    }
  } */

  .s-headline-super {
    font-size: theme("fontSize.7xl");
    line-height: theme("lineHeight.none");
    font-weight: theme("fontWeight.semibold");
  }
  @media screen(sm) {
    .s-headline-super {
      font-size: theme("fontSize.8xl");
    }
  }
  @media screen(lg) {
    .s-headline-super {
      font-size: theme("fontSize.9xl");
    }
  }

  /* Icons */
  .material-symbols-outlined {
    font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 48;
    cursor: default;
    user-select: none;
  }
  .material-symbols-outlined.icon-outline {
    font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 48;
  }
  .material-symbols-outlined.icon-fill {
    font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 48;
  }

  .marquee {
    overflow: hidden;
    position: relative;
    white-space: nowrap;
  }
  .marquee:hover .scrolling-container--pause-on-hover {
    animation-play-state: paused !important;
  }
  .marquee .scrolling-container {
    position: absolute;
    top: 50%;
    display: flex;
    flex-flow: row nowrap;
    transform: translate3d(0, -50%, 0);
  }
  .marquee .scroll-element {
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
  }

  @keyframes marquee {
    0% {
      transform: translate3d(0, -50%, 0);
    }
    100% {
      transform: translate3d(-50%, -50%, 0);
    }
  }
}

@layer utilities {
  /* Icons */
  .icon-xs {
    font-size: 16px;
  }
  .icon-sm {
    font-size: 20px;
  }
  .icon-base {
    font-size: 24px;
  }
  .icon-lg {
    font-size: 25px;
  }

  .link-reveal {
    color: currentColor;
    text-decoration: none;

    @apply transition-colors;
  }
  .link-reveal:hover {
    color: theme("colors.brand");
  }

  .columns-2 {
    max-width: 950px;
    columns: 2;
    column-width: 280px;
    column-gap: theme('spacing.10');
  }

  .details-reset summary {
    list-style-type: none;
  }

  .details-reset summary::-webkit-details-marker {
    display: none;
  }
}

.turbo-progress-bar {
  background: theme("colors.brand");
}

.swiper-wrapper {
  cursor: grab;
}

.swiper-wrapper:active {
  cursor: grabbing;
}

.swiper-pagination {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding-top: theme("spacing[1.5]");
  gap: theme("spacing[1.5]");
  display: flex;
}

.swiper-pagination-bullet {
  width: theme("spacing.2");
  height: theme("spacing.2");
  border-radius: theme("borderRadius.full");
  border: solid 1px theme("colors.fg.DEFAULT")
}
.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer;
}
.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: theme("colors.fg.DEFAULT");
}

/* Tagline Rotor */

.tagline-rotor__option {
  position: absolute;
  opacity: 0;
}

.tagline-rotor__option--in-out {
  animation-name: tagline-rotor__option--in-out;
  animation-duration: 3500ms;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.48, 0.08, 0.19, 1);
}

@keyframes tagline-rotor__option--in-out {
  0% {
      opacity: 0;
      transform: translateY(50%);
  }

  25%, 75% {
      opacity: 1;
      transform: translateY(0);
  }

  100% {
      opacity: 0;
      transform: translateY(-50%);
  }
}

.tagline-roto__option--fade-in-out {
  animation-name: tagline-roto__option--fade-in-out;
  animation-duration: 3500ms;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.48, 0.08, 0.19, 1);
}

@keyframes tagline-roto__option--fade-in-out {
  0% {
      opacity: 0;
  }

  25%, 75% {
      opacity: 1;
  }

  100% {
      opacity: 0;
  }
}

.mapboxgl-ctrl-logo {
    display: none !important;
}

/* .ProseMirror h2 {
  @apply font-semibold text-4xl mb-4 mt-8;
} */
/* .ProseMirror h3 {
  @apply font-semibold text-2xl text-gray mb-2;
} */
.ProseMirror ul {
  @apply list-disc ml-6;
}
.ProseMirror ol {
  @apply list-decimal ml-6;
}
.ProseMirror blockquote {
  @apply italic border-l-4 border-brand m-0 pl-4;
}
.ProseMirror hr {
  @apply my-8;
}

/* ProseMirror */

.ProseMirror h2 {
  font-size: theme("fontSize.4xl");
  line-height: theme("lineHeight.10");
  font-weight: theme("fontWeight.medium");
  max-width: 25ch;
  margin-bottom: theme("spacing.16");
}
@media screen(sm) {
  .ProseMirror h2 {
    font-size: theme("fontSize.5xl");
    line-height: theme("lineHeight.none");
  }
}
@media screen(lg) {
  .ProseMirror h2 {
    font-size: theme("fontSize.6xl");
    line-height: theme("lineHeight.none");
    letter-spacing: theme("letterSpacing.tight");
  }
}
.ProseMirror * + h2 {
  margin-top: theme("spacing.16");
}

.ProseMirror h3 {
  font-size: theme("fontSize.3xl");
  line-height: theme("lineHeight.9");
  font-weight: theme("fontWeight.medium");
  margin-bottom: theme("spacing.3");
}
.ProseMirror * + h3 {
  margin-top: theme("spacing.9");
}

.ProseMirror p {
  max-width: 640px;
}
.ProseMirror p + p {
  margin-top: theme("spacing.3");
}

.ProseMirror table {
  width: 100%;
}
.ProseMirror table tbody {
}
.ProseMirror table tr:first-of-type {
  position: sticky;
  top: 0;
  background-color: theme("colors.z0");

  color: theme("colors.primary");
}
.ProseMirror table tr {
  width: 100%;
}
.ProseMirror table td {
  padding: theme("spacing.2") 0;
  border-bottom: solid theme("colors.fg.secondary") 1px;
}
